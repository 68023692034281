import React from "react"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

export const PageHead = ({ title, description, pathname, children }) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
  }

  return (
      <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      {/* <meta name="image" content={seo.image} /> */}
      {/* <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" /> */}
      {/* <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="e1544ab2-6d93-4353-acb5-83ffb7ee76c5" data-blockingmode="auto" type="text/javascript"></script> */}
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[]; w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PS7WZ7G');`}</script>
      {children}
    </>
  )
}