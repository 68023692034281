import * as React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { breakpoint } from '../styles/variables'

const Paragraph = styled.p`
  margin-bottom: 1em;
  margin-top: 0;
`
const Wrapper = styled.div `
  display: grid;
  padding-top: 3rem;


  @media ${breakpoint.md} {
    grid-template-columns: 2fr 3fr;
    gap: 3rem;
    padding: 0 4rem;
    margin-top: 0;
    margin-bottom: 8rem;

   &:nth-of-type(n + 2) {
    grid-template-columns: 3fr 2fr;

    .gatsby-image-wrapper {
      grid-column: 2/3;
      grid-row: 1/2;
    }

    .text-wrapper {
      grid-column: 1/2;
      grid-row: 1/2;
    }
   }
  }



  .gatsby-image-wrapper{
      border-radius: 50%;
      width: 100%;
      justify-self: center;
      max-width: 250px;

      @media ${breakpoint.md} {
        align-self: center;
        justify-self: unset;
        max-width: 350px;
      }
    }
`

const TextWrapper = styled.div`
  padding: 1rem 0;
  z-index: 2;
  text-align: center;

  @media ${breakpoint.md} {
    padding: 0;
    text-align: left;
  }
`

const Title = styled.h2`
  text-align: center;

  @media ${breakpoint.md} {
    margin-top: 0;
    text-align: left;
  }
`

const About = () => {
  return (
    <>
      <Wrapper>
        <StaticImage src="../assets/images/Julia/julia_6.jpg" alt="Julia" />
        <TextWrapper class="text-wrapper">
          <Title>Jsem Julia</Title>
          <Paragraph>Vášnivá cestovatelka, holka, která miluje svět, i&nbsp;milovnice dobrého jídla, která si za těmi pravými chutěmi nebojí vydat na druhý konec světa.
            Za poslední roky jsem procestovala řadu zemí, setkala se s&nbsp;lidmi různých kultur v&nbsp;nespočetně státech a&nbsp;odhalila krásy a&nbsp;kouzla měst, která jsou vždycky trochu jiná.
            A&nbsp;ráda se o&nbsp;své zkušenosti i&nbsp;zážitky s&nbsp;vámi podělím.
          </Paragraph>
          <Paragraph>Sestavím vám itineráře na míru. Čerpám ze svých zkušeností a&nbsp;vím, kam se obrátit a&nbsp;kde najít ověřené a&nbsp;správné informace. Prozradím vám, kde najdete nejlepší restaurace, v&nbsp;nichž neutratíte víc, než je třeba, jak se dostanete hravě z&nbsp;letiště na hotel nebo kde se vůbec
          ubytovat. A&nbsp;co&nbsp;víc! Tipy na výlety, nevšední památky, ale i&nbsp;notoricky známá místa - všechno pohromadě v&nbsp;jednom jediném bloku.</Paragraph>
          <Paragraph>Pojďme společně naplánovat dovolenou, která se vám doslova vryje pod kůži.</Paragraph>
          {/* <Link to="/#places">Místa, která jsem navštívila</Link> */}
        </TextWrapper>
      </Wrapper>
      <Wrapper>
        <StaticImage src="../assets/images/simona/simona_1.jpg" alt="Simona" />
        <TextWrapper class="text-wrapper">
          <Title>Jsem Simona</Title>
            <Paragraph>Holka, co miluje svobodu a cestování, ráda zkouším nové věci a objevuji barevnost světa. Na svém cestovatelském seznamu mám již téměř 30 navštívených zemí a dlouhý seznam dalších, které ještě navštívit plánuji.</Paragraph>
            <Paragraph>Ze svých cest jsem si začala psát vlastní články a tipy týkající se cestování, které můžete na stránkách Cestu-j také vidět a nechat se jimi inspirovat.</Paragraph>
        </TextWrapper>
      </Wrapper>
    </>
  )
}

export default About